<messages>["./Registrar"]</messages>
<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw0>
    <v-col cols="12">
      <registrar-create-update
        :registrar-data="{ id }"
        @unsavedChanges="setUnsavedChanges"
        @success="success"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import RegistrarCreateUpdate from './components/RegistrarCreateUpdate'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import {mapMutations} from 'vuex'

  export default {
    name: 'RegistrarEdit',

    components: {
      BaseLayout,
      RegistrarCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      back () {
        this.$router.back ()
      },

      success (data) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('createUpdate.successUpdate', {link: data.name}),
          objects: [{
            name: data.name,
            link: {
              name: 'registrar.view',
              params: {id: data.id}
            }
          }]
        })
        this.back ()
      }
    }
  }
</script>
